<template>
  <div class="page-title-container container d-flex flex-wrap justify-content-between align-items-center" :class="{ 'mb-5' : !removeMargin }">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'page-title-container',
  props: {
    removeMargin: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
